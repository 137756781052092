
































































import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import { DATE_FORMAT_REPORT, DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import { FormModel } from "ant-design-vue";
import MNotificationVue from "@/mixins/MNotification.vue";
import { reportService } from "@/services-v2/report.service";
import { downloadFile } from "@/helpers/file-reader";
import { ReportAgingDetail } from "@/models/interface-v2/report.interface";
import { Mode } from "@/models/enums/global.enum";
@Component({
  name: "ReportAgingDetails",
  mixins: [
    MNotificationVue,
  ],
  components: {
    CSelectCustomer: () => import("@/components/shared/select-customer/CSelectCustomer.vue"),
    CSelectCurrency: () => import(/*webpackPrefetch: true */"@/components/shared/select-currency/CSelectCurrency.vue"),
  }
})
export default class AgingDetail extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form = {
    branchId: undefined as string | undefined,
    customerId: undefined as string | undefined,
    currencyId: undefined as string | undefined,
    asOfDate: moment().format()
  }
  rules = {
    branchId: [{ required: true, message: (): string => this.$t("lbl_validation_required_error").toString() }],
    currencyId: [{ required: true, message: (): string => this.$t("lbl_validation_required_error").toString() }],
    customerId: [{ required: false, message: (): string => this.$t("lbl_validation_required_error").toString() }],
  }
  loading = false;

  get isModeView(): boolean {
    return this.$route.meta.mode === Mode.VIEW;
  }
  
  async handleDownload(): Promise<void> {
    try {
      this.loading = true;
      const params: ReportAgingDetail = {
        branchId: this.form.branchId || "",
        customerId: this.form.customerId,
        currencyId: this.form.currencyId,
        date: moment(this.form.asOfDate).format(DATE_FORMAT_REPORT),
      };
      const file = await reportService.downloadAgingDetails(params);
      downloadFile(new Blob([file]), `report_aging_details_${params.date}.xlsx`);
    } catch (error) {
      this.showErrorMessage("notif_download_error");
    } finally {
      this.loading = false;
    }
  }

  validateForm(): void {
    const form = this.$refs.form as FormModel;
    form.validate((valid: boolean) => {
      if (valid) {
        this.handleDownload();
        } else {
        this.showNotifValidationError();
      }
    });
  }

  handleClear(): void {
    const form = this.$refs.form as FormModel;
    form.resetFields();
  }
  handleBack(): void {
    this.$router.back();
  }
}
