var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_report_aging_details") } },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-align": "left",
            "label-col": { span: 4 },
            "wrapper-col": { span: 14 }
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_branch"), prop: "branchId" } },
            [
              _c("CSelectBranch", {
                model: {
                  value: _vm.form.branchId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "branchId", $$v)
                  },
                  expression: "form.branchId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { prop: "customerId", label: _vm.$t("lbl_customer_name") }
            },
            [
              _c("CSelectCustomer", {
                model: {
                  value: _vm.form.customerId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "customerId", $$v)
                  },
                  expression: "form.customerId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_currency"), prop: "currencyId" } },
            [
              _c("CSelectCurrency", {
                attrs: { "prop-default": _vm.isModeView },
                model: {
                  value: _vm.form.currencyId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "currencyId", $$v)
                  },
                  expression: "form.currencyId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_as_of_date") } },
            [
              _c("a-date-picker", {
                attrs: {
                  placeholder: _vm.$t("lbl_choose"),
                  format: _vm.DEFAULT_DATE_FORMAT,
                  disabled: ""
                },
                model: {
                  value: _vm.form.asOfDate,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "asOfDate", $$v)
                  },
                  expression: "form.asOfDate"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { "wrapper-col": { span: 14, offset: 4 } } },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleClear } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_clear")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loading,
                        icon: "download"
                      },
                      on: { click: _vm.validateForm }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }